// src/auth.js

export async function authenticate(initData) {
    console.log('Отправка GET-запроса на авторизацию с initData');
    try {
        const response = await fetch(process.env.REACT_APP_API_URL+`v2/auth?initData=${encodeURIComponent(initData)}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });

        console.log('Ответ от сервера:', response.status);

        if (!response.ok) {
            throw new Error('Authentication failed');
        }

        const data = await response.json();
        console.log('Полученные данные от сервера:', data);
        return data.token; // JWT
    } catch (error) {
        console.error('Error during authentication:', error);
        throw error;
    }
}