// src/contexts/AuthContext.js
import React, { createContext } from 'react';
import useAuth from '../hooks/useAuth';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const token = useAuth();

    return (
        <AuthContext.Provider value={token}>
            {children}
        </AuthContext.Provider>
    );
};