// src/components/MapCell.js
import React from 'react';
import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';

const MapCell = ({
                     pointId,
                     chipLabel,
                     chipColor,
                     bottomLabel,
                 }) => {
    return (
        <Box
            sx={{
                width: '100%', // Заполняет ширину столбца
                aspectRatio: '1 / 1', // Поддерживает соотношение сторон 1:1 для квадратной формы
                bgcolor: '#ecf0f1',
                borderRadius: 2,
                boxShadow: 0,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between', // Распределяем зоны равномерно
                alignItems: 'center',
                p: 0,
                m: 0, // Убираем внешние отступы, управление отступами через сетку
                overflow: 'hidden', // Скрываем переполнение
                border: `0.1px solid #000`, // Добавляем ободок
                boxSizing: 'border-box', // Обеспечиваем, что ободок не увеличивает размер ячейки
            }}
        >
            {/* Верхняя зона: 30% высоты */}
            <Box
                sx={{
                    p:0.4,
                    bgcolor: chipColor,
                    height: '30%',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: 1,
                }}
            >

                {chipLabel}



            </Box>



            {/* Нижняя зона: 40% высоты */}
            <Box
                sx={{
                    height: '40%',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    px: 1, // Внутренние отступы по горизонтали
                }}
            >

                <Typography
                    variant="body2"
                    sx={{
                        color: 'text.secondary', // Настраиваемый цвет текста
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        width: '100%', // Каждое поле занимает 45% ширины для отступа
                        textAlign: 'center',
                    }}
                >
                    {bottomLabel}
                </Typography>
            </Box>
        </Box>
    );
};

MapCell.propTypes = {
    pointId: PropTypes.string.isRequired,
    bottomLabel: PropTypes.string.isRequired,
    chipLabel: PropTypes.string.isRequired,
    chipColor: PropTypes.string.isRequired,
};

export default MapCell;