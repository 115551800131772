import { styled } from '@mui/material';
import Box from '@mui/material/Box';

const CustomBox = styled(Box)(({ theme }) => ({
    backgroundColor: '#F1F2F6', // Фон карточки
    borderRadius: '12px', // Закругленные углы
    // boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)', // Тень
    boxShadow: 0, // Тень

    padding: theme.spacing(2), // Внутренний отступ
}));

export default CustomBox;