import React, { useState } from 'react';
import { Tabs, Tab, Box } from '@mui/material';

const MainTabs = ({ children }) => {
    const [value, setValue] = useState(1);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box sx={{ width: '100%' }}>
            <Tabs value={value} onChange={handleChange} centered>
                <Tab label="Action" />
                <Tab label="Map" />
                <Tab label="Profile" />
                <Tab label="Mail" />
            </Tabs>
            <Box sx={{ px: 0, py:3 }}>
                {React.Children.toArray(children)[value]}
            </Box>
        </Box>
    );
};

export default MainTabs;