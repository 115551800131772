import { useState, useEffect } from 'react';
import { authenticate } from '../auth';

const useAuth = () => {
    const [token, setToken] = useState(null);

    useEffect(() => {
        const initAuth = async () => {
            if (process.env.NODE_ENV === 'production') {
                const initData = window.Telegram?.WebApp?.initData || '';
                try {
                    const jwtToken = await authenticate(initData);
                    setToken(jwtToken);
                    window.Telegram.WebApp.ready();
                } catch (error) {
                    console.error('Authentication failed:', error);
                }
            } else {
                try {
                    const jwtToken = await authenticate('');
                    setToken(jwtToken);
                } catch (error) {
                    console.error('Mock Authentication failed:', error);
                }
            }
        };

        initAuth();
    }, []);

    return token;
};

export default useAuth;