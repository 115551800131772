// src/components/UnderConstructionTab.js
import React from 'react';
import { Card, CardContent, Typography, Box } from '@mui/material';

const UnderConstructionTab = () => {
    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <Card sx={{ maxWidth: 400, textAlign: 'center' }}>
                <CardContent>
                    <Typography variant="h5" component="div">
                        Under Construction
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        This section is currently being developed. Stay tuned!
                    </Typography>
                </CardContent>
            </Card>
        </Box>
    );
};

export default UnderConstructionTab;