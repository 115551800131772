import React from 'react';
import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';

const IconText = ({ icon, text, iconSize = 20, spacing = 1 }) => {
    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center', // Центрирование по вертикали
                gap: spacing, // Отступ между иконкой и текстом
            }}
        >
            <Box
                sx={{
                    fontSize: iconSize, // Размер иконки
                    display: 'flex',
                    alignItems: 'center', // Центрирование иконки
                }}
            >
                {icon}
            </Box>
            <Typography
                variant="body2" // Размер текста
                sx={{ lineHeight: 1 }} // Убираем лишние отступы
            >
                {text}
            </Typography>
        </Box>
    );
};

IconText.propTypes = {
    icon: PropTypes.element.isRequired, // Иконка, передаётся как JSX-элемент
    text: PropTypes.string.isRequired, // Текст, отображаемый рядом с иконкой
    iconSize: PropTypes.number, // Размер иконки (по умолчанию 20)
    spacing: PropTypes.number, // Отступ между иконкой и текстом (по умолчанию 1)
};

export default IconText;